import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Login from './pages/login/Login';
import NoPage from './pages/404';
import Register from './pages/register/Register';
import Pricing from './pages/pricing/Pricing';
import Home from './pages/home/Home';
import Dashboard from './dashboardPages/dashboard/Dashboard';
import ManageChannel from './dashboardPages/channel/manage_channel/ManageChannel';
import MapUnmapProducts from './dashboardPages/channel/channel_products/MapUnmapProducts';
import ManageStock from './dashboardPages/master/manage_stock/ManageStock';
import Job__batch_Management from './dashboardPages/master/job_management/Job__batch_Management'
import AddChannel from './dashboardPages/channel/channel_add_setting/AddChannel';
import RechargePlans from './dashboardPages/recharge/RechargePlans';
import ComboData from './dashboardPages/master/combo_data/ComboData';
import UpdateComboData from './dashboardPages/master/combo_data/UpdateComboData';
import RemoveComboData from './dashboardPages/master/combo_data/RemoveComboData';
import AddUpdateStock from './dashboardPages/master/manage_stock/AddUpdateStock';
import ManageProducts from './dashboardPages/master/manage_products/ManageProducts';
import AddProduct from './dashboardPages/master/manage_products/AddProduct';
import AddProduct2 from './dashboardPages/master/manage_products/AddProduct2';
import { QueryClient, QueryClientProvider} from 'react-query';
import Warehouse from './dashboardPages/WMS/warehouse/Warehouse';
import ContactPage from './pages/contact/ContactPage';
import AboutPage from './pages/about/AboutPage';
import ServicePage from './pages/service/ServicePage';
import Marketplace from './dashboardPages/marketplace/marketplace/Marketplace';
import MarketplaceOrdersBasicTab from './dashboardPages/marketplace/orders/MarketplaceOrders';
import PlaceOrder from './dashboardPages/marketplace/orders/PlaceOrder';
import { CHANNEL_ORDER_PATH, DASHBOARD_BASE_PATH, INVOICE_PATH, JOB_MANAGEMENT, MANAGE_VARIATION_PATH, PROFILE, SETTLEMENT_PATH, SHIPPING_PARTNER_DASHBOARD, STOCK_HISTORY_PATH, UPDATE_REMOVE_VARIATION_PATH } from './custome/constants';
import Profile from './dashboardPages/user_actions/Profile';
import JobManagement from './dashboardPages/master/job_management/JobManagement';
import MainDashboard from './components/dashboardTemplate/MainDashboard';
import ShippingPartnerDashboard from './dashboardPages/shipping_partner/ShippingPartnerDashboard';
import ManageVariation from './dashboardPages/master/manage_variations/ManageVariation';
import UpdateRemoveVariation from './dashboardPages/master/manage_variations/UpdateRemoveVariation';
import ChannelOrders from './dashboardPages/channel/channel_orders/ChannelOrders';
import SPAPIAuthorization from './dashboardPages/auth_pages/SPAPIAuthorization';
import StockHistoryReports from './dashboardPages/reports/stock_history_reports/StockHistoryReports';
import FlipkartAuthorization from './dashboardPages/auth_pages/FlipkartAuthorization';
import Invoice from './dashboardPages/marketplace/orders/Invoice';
import Settlement from './dashboardPages/marketplace/settlement/Settlement';

function App() {
  const queryClient =new QueryClient();
  return (
    <div >


      <QueryClientProvider client={queryClient}>
        <BrowserRouter>

          <Routes>


            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/service" element={<ServicePage/>}/>
            <Route path="/*" element={<NoPage />} />

            {/* login user */}
            <Route path={DASHBOARD_BASE_PATH} element={<MainDashboard />} >
              <Route index element={<Dashboard />} />
              <Route path="/dashboard/manage_channel" element={<ManageChannel />} />
              <Route path="/dashboard/add_channel" element={<AddChannel />} />
              <Route path="/dashboard/job_batch_management" element={<Job__batch_Management />} />
              <Route path="/dashboard/add_or_update_stock" element={<AddUpdateStock />}></Route>
              <Route path="/dashboard/manage_stock" element={<ManageStock />}></Route>
              <Route path='/dashboard/recharge_plans' element={<RechargePlans />}></Route>
              <Route path="/dashboard/mapped_unmmaped_products" element={<MapUnmapProducts />}></Route>
              <Route path="/dashboard/combo_data" element={<ComboData />}></Route>
              <Route path="/dashboard/update_combo_data" element={<UpdateComboData />}></Route>
              <Route path="/dashboard/remove_combo_data" element={<RemoveComboData />}></Route>
              <Route path="/dashboard/manage_products" element={<ManageProducts />}></Route>
              <Route path="/dashboard/add_product" element={<AddProduct />}></Route>
              <Route path="/dashboard/add_product2" element={<AddProduct2 />}></Route>
              <Route path="/dashboard/manage_warehouse" element={<Warehouse />}></Route>
              <Route path="/dashboard/manage_marketplace" element={ <Marketplace/>}></Route>
              <Route path="/dashboard/marketplace_orders" element={ <MarketplaceOrdersBasicTab/>}></Route>
              <Route path="/dashboard/place_marketplace_orders" element={ <PlaceOrder/>}></Route>
              <Route path={DASHBOARD_BASE_PATH+SHIPPING_PARTNER_DASHBOARD} element={ <ShippingPartnerDashboard/>}></Route>
              <Route path={DASHBOARD_BASE_PATH+PROFILE} element={<Profile/>}></Route>
              <Route path={DASHBOARD_BASE_PATH+JOB_MANAGEMENT} element={<JobManagement/>}></Route>
              <Route path={DASHBOARD_BASE_PATH+MANAGE_VARIATION_PATH} element={<ManageVariation/>}></Route>
              <Route path={DASHBOARD_BASE_PATH+UPDATE_REMOVE_VARIATION_PATH} element={<UpdateRemoveVariation />}></Route>
              <Route path={DASHBOARD_BASE_PATH+CHANNEL_ORDER_PATH} element={<ChannelOrders />}></Route>
              <Route path={DASHBOARD_BASE_PATH+'/amazon_callback'} element={<SPAPIAuthorization />}></Route>
              <Route path={DASHBOARD_BASE_PATH+'/flipkart_callback'} element={<FlipkartAuthorization />}></Route>
              <Route path={DASHBOARD_BASE_PATH+STOCK_HISTORY_PATH} element={<StockHistoryReports />}></Route>
              <Route path={DASHBOARD_BASE_PATH+SETTLEMENT_PATH} element={<Settlement />}></Route>
              <Route path={DASHBOARD_BASE_PATH+INVOICE_PATH} element={<Invoice />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;

// import React from 'react'
// import Home from './pages/home/Home'
// import { BrowserRouter, Route, Routes } from 'react-router-dom'

// export default function App() {
//   return (
//     <div>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Home />} />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   )
// }
